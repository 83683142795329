require("../_common/common");
require("../_common/index");

require("./error.css");

common.scroll.scrollItem(
  5,
  "#src_item1 .arr_left",
  "#src_item1 .arr_right",
  "#src_item1 .scroll_num",
  "#src_item1 .downContent",
  0,
  1
);
